import { Product, ProductToBuy } from '../../interface/product';

export default function getProductsToBuy({
  products,
  productsToBuy,
  scannedQuantity,
}: {
  products: Product[];
  productsToBuy: ProductToBuy[];
  scannedQuantity?: number;
}) {
  let scannedQuantityRemaining = scannedQuantity;
  return [...products]
    .sort((a, b) => Number(b.prezzo) - Number(a.prezzo))
    .map((item) => {
      let totalQuantity = 0;
      const { quantita = 0 } = productsToBuy.find((itemToBuy) => itemToBuy.id_prodotto === item.id) || {};
      const singleProductAmount = Number(item.prezzo);

      if (
        scannedQuantityRemaining &&
        scannedQuantityRemaining > 0 &&
        productsToBuy.length === 0 &&
        item.tipologia === 'donazione_libera'
      ) {
        const productScannedQuantity = Math.floor(scannedQuantityRemaining / singleProductAmount);
        totalQuantity = productScannedQuantity + quantita;
        scannedQuantityRemaining -= productScannedQuantity * singleProductAmount;
      } else {
        totalQuantity = quantita;
      }

      return {
        ...item,
        id_prodotto: item?.id,
        quantita: totalQuantity,
        importo: singleProductAmount * totalQuantity,
      };
    })
    .filter((item) => item !== null) as ProductToBuy[];
}
