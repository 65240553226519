import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { saveSummary } from '../api/saveSummary';
import { parseExternalEvents } from '../components/summary/utils/parseExternalEvents';
import { ISummaryData } from '../interface/summary';
import { defaultSummaryValues } from '../components/summary/lib/defaultSummaryValues';

const { qt_distribuiti_digitale, tot_donazioni_digitale, tot_distribuiti, ...defaultBaseValues } = defaultSummaryValues;

const defaultValues = {
  ...defaultBaseValues,
  qt_piazze_esterne: 0,
  qt_date_piazze_esterne: 0,
} as const;

export const useEmptySummaryCreate = (token: string) => {
  const { mutateAsync, isLoading } = useMutation<ISummaryData, Error, ISummaryData>((data: ISummaryData) => {
    return saveSummary(data, token);
  });

  const createEmptySummary = async () => {
    const { qt_piazze_esterne, qt_date_piazze_esterne } = defaultSummaryValues;
    const parsedExternalEvents = parseExternalEvents(qt_piazze_esterne);
    const parsedExternalEventsTo = parseExternalEvents(qt_date_piazze_esterne);

    const data = {
      ...defaultValues,
      qt_piazze_esterne: parsedExternalEvents,
      qt_date_piazze_esterne: parsedExternalEventsTo,
    };

    await mutateAsync(data, {
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  return { createEmptySummary, isLoading };
};
