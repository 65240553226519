import { ISummaryFormData } from '../../../interface/summary';

export const defaultSummaryValues: ISummaryFormData = {
  qt_ricevuti: 0,
  qt_distribuiti_digitale: 0,
  qt_distribuiti_non_registrati: 0,
  qt_distribuiti: 0,
  qt_non_utilizzabili: 0,
  qt_rimanenze: 0,
  tot_donazioni_digitale: 0,
  tot_donazioni_non_registrati: 0,
  tot_distribuiti: 0,
  tot_distribuiti_non_registrati: 0,
  tot_contributi: 0,
  qt_ricevute_cartacee: 0,
  qt_ricevute_digitali: 0,
  qt_richiesta_anno_successivo: 0,
  qt_piazze_esterne: [{ nome: '', quantità: '' }],
  qt_date_piazze_esterne: [{ nome: '', quantità: '' }],
  commenti: '',
  orario_inizio: new Date(),
  orario_fine: new Date(),
};
