/* eslint-disable max-lines */
import { Box } from '@mui/material';
import NavBar from '../components/commons/NavBar';
import { useNavigate } from 'react-router-dom';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RendicontazioniButton from '../components/commons/RendicontazioniButton';
import { useQuery } from 'react-query';
import { useAuth } from '../components/auth/AuthProvider';
import { fetchSummary } from '../api/fetchSummary';
import { SkeletonReport } from '../components/commons/Skeleton';
import { useEmptySummaryCreate } from '../hooks/useEmptySummaryCreate';
import { toast } from 'react-toastify';

const REACT_APP_ALLEGATOB_ENABLE = process.env.REACT_APP_ALLEGATOB_ENABLE === 'true';

export const RendicontazioneMenu = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const {
    data,
    isLoading,
    refetch: refetchRendicontazione,
  } = useQuery(['getRendicontazione'], () => fetchSummary(token), {
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const { createEmptySummary, isLoading: createSummaryLoading } = useEmptySummaryCreate(token);

  const rendicontazioneExists = () => {
    return data && Object.values(data).length > 0;
  };

  const createRendicontazioneIfNotExistsAndNavigateTo = async (path: string) => {
    if (isLoading || createSummaryLoading) return;
    if (!rendicontazioneExists()) {
      await createEmptySummary();
      const { data } = await refetchRendicontazione();
      if (!data || !(Object.values(data).length > 0)) return toast.error('Si è verificato un problema');
    }
    navigate(path);
  };

  return (
    <div className="container-main" style={{ overflowY: 'scroll' }}>
      <h1>Consuntivo punto distribuzione</h1>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        height="100%"
        padding={2}
        gap={2}
        overflow="y"
      >
        {isLoading ? (
          <SkeletonReport />
        ) : (
          <>
            <RendicontazioniButton
              disabled={!REACT_APP_ALLEGATOB_ENABLE}
              onClick={() => navigate('summary')}
              label="Rendiconto - Allegato B"
              icon={<ReceiptLongOutlinedIcon fontSize="large" color="primary" />}
            />
            <RendicontazioniButton
              onClick={() => createRendicontazioneIfNotExistsAndNavigateTo('ddt')}
              label={'Documento di trasporto'}
              icon={<LocalShippingOutlinedIcon fontSize="large" color="primary" />}
            />
            <RendicontazioniButton
              onClick={() => createRendicontazioneIfNotExistsAndNavigateTo('merce-danneggiata')}
              label={'Merce danneggiata'}
              icon={<SentimentVeryDissatisfiedOutlinedIcon fontSize="large" color="primary" />}
            />
          </>
        )}
      </Box>
      <NavBar />
    </div>
  );
};
