/* eslint-disable max-lines */
import { InputAdornment, Paper, TextField } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { formatPrice } from '../../../utils/formatPrice';

interface ContributionPanelProps {
  closedEvent: boolean;
}

export default function ContributionPanel({ closedEvent }: ContributionPanelProps) {
  const { control } = useFormContext();
  const [
    tot_donazioni_digitale,
    tot_donazioni_non_registrati,
    tot_distribuiti,
    tot_contributi,
    tot_distribuiti_non_registrati,
  ] = useWatch({
    control,
    name: [
      'tot_donazioni_digitale',
      'tot_donazioni_non_registrati',
      'tot_distribuiti',
      'tot_contributi',
      'tot_distribuiti_non_registrati',
    ],
  });

  const totProdotti = useMemo(() => {
    return Number(tot_distribuiti || 0) + Number(tot_distribuiti_non_registrati || 0);
  }, [tot_distribuiti, tot_distribuiti_non_registrati]);

  const totDonazioni = useMemo(() => {
    return Number(tot_donazioni_digitale || 0) + Number(tot_donazioni_non_registrati || 0);
  }, [tot_donazioni_digitale, tot_donazioni_non_registrati]);

  const totRegistrati = useMemo(() => {
    return Number(tot_distribuiti || 0) + Number(tot_donazioni_digitale || 0);
  }, [tot_distribuiti, tot_donazioni_digitale]);

  const totNonRegistrati = useMemo(() => {
    return Number(tot_distribuiti_non_registrati || 0) + Number(tot_donazioni_non_registrati || 0);
  }, [tot_distribuiti_non_registrati, tot_donazioni_non_registrati]);

  return (
    <div
      className="person-card-box"
      style={{
        backgroundColor: '#ffffff',
        padding: 10,
        borderRadius: 8,
        textAlign: 'center',
      }}
    >
      <Paper
        variant="outlined"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          textAlign: 'center',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <i>
          <small>Incasso da cessione prodotti</small>
        </i>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            disabled
            value={formatPrice(tot_distribuiti)}
            sx={{
              input: {
                textAlign: 'center',
                fontWeight: '900',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
              },
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'center',
                fontSize: '10px',
                margin: 0,
                marginTop: '3px',
                lineHeight: '0.8rem',
              },
            }}
            size="small"
            type="text"
            helperText="registrati in donandi"
            variant="standard"
          />
          {'+'}
          <Controller
            name="tot_distribuiti_non_registrati"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                {...field}
                error={!!error}
                sx={{
                  input: {
                    textAlign: 'center',
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '10px',
                    margin: 0,
                    marginTop: '3px',
                    color: 'rgba(0, 0, 0, 0.38)',
                    lineHeight: '0.8rem',
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
                helperText={error?.message ? error?.message : 'registrazione cartacea'}
                type="number"
              />
            )}
          />
          <span>=</span>
          <TextField
            disabled
            value={formatPrice(totProdotti)}
            sx={{
              input: {
                textAlign: 'center',
                fontWeight: '900',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#00ACDE',
              },
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'center',
                fontSize: '10px',
                margin: 0,
              },
            }}
            size="small"
            type="text"
            helperText="totale"
            variant="standard"
          />
        </div>
      </Paper>
      {'+'}
      <Paper
        variant="outlined"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          textAlign: 'center',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <i>
          <small>Incasso da donazioni aggiuntive</small>
        </i>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Controller
            name="tot_donazioni_digitale"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                disabled
                size="small"
                sx={{
                  input: {
                    textAlign: 'center',
                    fontWeight: '900',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'black',
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '10px',
                    margin: 0,
                    marginTop: '3px',
                    lineHeight: '0.8rem',
                  },
                }}
                {...field}
                value={formatPrice(field.value)}
                error={!!error}
                type="text"
                variant="standard"
                helperText="registrati in donandi"
              />
            )}
          />
          <span>+</span>
          <Controller
            name="tot_donazioni_non_registrati"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                {...field}
                error={!!error}
                sx={{
                  input: {
                    textAlign: 'center',
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '10px',
                    margin: 0,
                    marginTop: '3px',
                    color: 'rgba(0, 0, 0, 0.38)',
                    lineHeight: '0.8rem',
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
                helperText={error?.message ? error?.message : 'registrazione cartacea'}
                type="number"
              />
            )}
          />
          <span>=</span>
          <TextField
            disabled
            value={formatPrice(totDonazioni)}
            sx={{
              input: {
                textAlign: 'center',
                fontWeight: '900',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#00ACDE',
              },
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'center',
                fontSize: '10px',
                margin: 0,
              },
            }}
            size="small"
            type="text"
            helperText="totale"
            variant="standard"
          />
        </div>
      </Paper>
      {'='}
      <Paper
        variant="outlined"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          textAlign: 'center',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <i>
          <small>Incasso totale</small>
        </i>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <TextField
            disabled
            value={formatPrice(totRegistrati)}
            sx={{
              input: {
                textAlign: 'center',
                fontWeight: '900',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#00ACDE',
              },
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'center',
                fontSize: '10px',
                margin: 0,
              },
            }}
            size="small"
            type="text"
            helperText="registrati in donandi"
            variant="standard"
          />
          <span>+</span>
          <TextField
            disabled
            value={formatPrice(totNonRegistrati)}
            sx={{
              input: {
                textAlign: 'center',
                fontWeight: '900',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#00ACDE',
              },
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'center',
                fontSize: '10px',
                margin: 0,
              },
            }}
            size="small"
            type="text"
            helperText="registrazione cartacea"
            variant="standard"
          />
          <span>=</span>
          <TextField
            disabled
            value={formatPrice(tot_contributi)}
            sx={{
              input: {
                textAlign: 'center',
                fontWeight: '900',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#00ACDE',
              },
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'center',
                fontSize: '10px',
                margin: 0,
              },
            }}
            size="small"
            type="text"
            helperText="totale"
            variant="standard"
          />
        </div>
      </Paper>
    </div>
  );
}
