/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, FormControl, IconButton } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Contact } from '../../interface/contact';
import { NuovaDonazione } from '../../interface/donazione';
import { useProducts } from '../products/ProductProvider';
import ConfirmModal from '../commons/ConfirmModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CodeChip } from '../commons/CodeChip';
import { SkeletonForm } from '../commons/Skeleton';
import { formatPrice } from '../../utils/formatPrice';
import { useLocation, useNavigate } from 'react-router-dom';
import useDonazione from '../../hooks/useDonazione';
import DonazioneCents from './DonazioneCents/DonazioneCents';
import { ProductToBuy } from '../../interface/product';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const NEXI_ENABLE = process.env.REACT_APP_NEXI_ENABLE === 'true';

interface Props {
  contact: Contact | null;
  onSubmit: (value: NuovaDonazione) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export const DonazioneForm = ({ contact, onSubmit, onCancel, isLoading: createLoading }: Props) => {
  const { products, isLoading: productsIsLoading } = useProducts();
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const { control, getValues, setValue, watch } = useFormContext();
  const navigate = useNavigate();
  const location = useLocation();
  const metodoPagamento = watch('metodo_pagamento');

  const getScannedQuantity = () => {
    const scannedQuantity = location.state?.receipt?.qty;
    return scannedQuantity ? Number(scannedQuantity) : 0;
  };

  const {
    productsToBuy,
    freeOffer,
    isAmountLess,
    totalToPay,
    setProducts,
    incrementQuantity,
    incrementQuantityByAmount,
    decrementQuantity,
  } = useDonazione({
    initialImporto: getScannedQuantity(),
    initialProducts: products,
    onChangeImporto: (value) => setValue('importo', value),
    onChangeProducts: (values) => setValue('prodotti', values),
  });

  useEffect(() => {
    if (products && !productsIsLoading) {
      setProducts(products, getScannedQuantity());
    }
  }, [products]);

  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (totalToPay !== 0) {
      setSaveModalOpen(true);
    }
  };

  const handleSaveDonation = () => {
    const data = getValues();
    onSubmit(data as any);
    setSaveModalOpen(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCancelModalOpen(true);
  };

  const handleCancelDonation = () => {
    setCancelModalOpen(false);
    onCancel();
  };

  sessionStorage.getItem('hasPos') === 'true' && navigate('/');

  if (!contact) {
    return (
      <div className="container-main">
        <Alert severity="warning"> Nessun contatto selezionato</Alert>
      </div>
    );
  }

  if (productsIsLoading) {
    return <SkeletonForm />;
  }

  if (!products || products?.length === 0) {
    return <Alert severity="error">Nessun prodotto trovato</Alert>;
  }

  setValue('id_contatto', contact.id);

  const renderProductItem = (prod: ProductToBuy) => {
    switch (prod.nome) {
      case 'Donazione libera centesimi':
        return <DonazioneCents product={prod} onIncrementQuantity={incrementQuantityByAmount} />;
      default:
        return (
          <>
            <div className="product-info">
              <span>{prod.nome}</span>
              <span>
                {formatPrice(prod.prezzo)}{' '}
                {prod.quantita > 1 && `x ${prod.quantita} = ${formatPrice(prod.prezzo * prod.quantita)}`}
              </span>
            </div>
            <div className="product-quantity">
              <IconButton
                aria-label="remove"
                color="primary"
                disabled={prod.quantita === 0}
                onClick={() => decrementQuantity(prod.id)}
              >
                <RemoveCircleIcon />
              </IconButton>
              <span style={{ width: '20px', textAlign: 'center' }}>{prod.quantita}</span>

              <IconButton aria-label="add" color="primary" onClick={() => incrementQuantity(prod.id)}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </>
        );
    }
  };

  return (
    <div className="container-main">
      <h1 style={{ marginBottom: 30 }}>Nuova donazione</h1>
      <CodeChip contactId={contact.id} contactName={`${contact.nome} ${contact.cognome}`} />
      <form style={{ marginTop: -30 }}>
        <Controller
          name="metodo_pagamento"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl size="small">
              <p>Metodo di pagamento</p>
              <ToggleButtonGroup
                {...field}
                value={[field.value]}
                sx={{ width: '100%', display: 'flex' }}
                color="primary"
              >
                <ToggleButton sx={{ flex: 1 }} value="contanti">
                  Contanti
                </ToggleButton>
                <ToggleButton sx={{ flex: 1 }} value="pos">
                  Pos
                </ToggleButton>
                {NEXI_ENABLE && (
                  <ToggleButton sx={{ flex: 1 }} value="paybylink">
                    Pay By Link
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </FormControl>
          )}
        />

        {!metodoPagamento ? (
          <Alert severity="info" sx={{ marginBottom: '1em', marginTop: '1em' }}>
            Seleziona un metodo di pagamento
          </Alert>
        ) : (
          <>
            <div className="products-list">
              {productsToBuy.map((prod, index) => {
                return (
                  <div className="product-container" key={index}>
                    {renderProductItem(prod)}
                  </div>
                );
              })}
            </div>

            <div
              style={{
                fontSize: '1.3rem',
                textAlign: 'end',
                marginTop: -40,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p>Totale donazione </p>
              <p>
                <strong style={{ color: '#4b7e33' }}> {formatPrice(totalToPay + freeOffer)}</strong>
              </p>
            </div>
          </>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSave}
            disabled={!metodoPagamento || isAmountLess || createLoading || !(totalToPay + freeOffer)}
            startIcon={createLoading && <CircularProgress size="1rem" color="inherit" />}
          >
            Salva donazione
          </Button>

          <Button variant="outlined" type="submit" onClick={handleCancel} disabled={createLoading}>
            Annulla
          </Button>
        </Box>
      </form>

      <ConfirmModal
        open={saveModalOpen}
        title="Conferma Donazione"
        text={
          <>
            Sei sicuro di voler confermare la nuova donazione di
            <br />
            <span style={{ color: '#006F9A', fontWeight: '900' }}>{formatPrice(totalToPay + freeOffer)}</span>
            <br />
            utilizzando il metodo di pagamento
            <br />
            <span style={{ color: '#006F9A', fontWeight: '900' }}>{metodoPagamento}</span>
          </>
        }
        confirmButtonText="Salva"
        onCancel={() => setSaveModalOpen(false)}
        onConfirm={() => handleSaveDonation()}
        backButtonText="Annulla"
      />
      <ConfirmModal
        open={cancelModalOpen}
        text={"Vuoi annullare l'inserimento?"}
        confirmButtonText="Annulla inserimento"
        onCancel={() => setCancelModalOpen(false)}
        onConfirm={() => handleCancelDonation()}
        backButtonText="Torna alla donazione"
      />
    </div>
  );
};
