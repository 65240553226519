import moment from 'moment';
import { Donazione } from '../../interface/donazione';
import DonazioneItem from './DonazioneItem';
import { DonazioneStatusChip } from '../commons/DonazioneStatusChip';
import { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import ConfirmModal from '../commons/ConfirmModal';
import { formatPrice } from '../../utils/formatPrice';

interface Props {
  donazione: Donazione;
  onRetryPayment: (donazione: Donazione) => void;
  loading?: boolean;
}

const getMetodoPagamentoLabel = (metodoPagamento: Donazione['metodo_pagamento']) => {
  switch (metodoPagamento) {
    case 'paybylink':
      return 'Pay By Link';
    case 'pos':
      return 'POS';
    case 'contanti':
      return 'Contanti';
    default:
      return '-';
  }
};

const DonazioneCard: React.FC<Props> = ({ donazione, onRetryPayment, loading = false }) => {
  const [retryModalOpen, setRetryModalOpen] = useState(false);

  const isPayByLink = useMemo(() => donazione.metodo_pagamento === 'paybylink', [donazione.metodo_pagamento]);
  const canRetryPayment = useMemo(
    () => isPayByLink && donazione.status !== 'COMPLETED',
    [donazione.status, isPayByLink],
  );

  return (
    <div className="person-card">
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h3>
              {donazione.id} - {donazione.nome_contatto} {donazione.cognome_contatto}
            </h3>
            <DonazioneStatusChip status={donazione.status} show />
          </div>
          {donazione.prodotti && donazione.prodotti.map((prodotto, i) => <DonazioneItem key={i} prodotto={prodotto} />)}
          <div className="space-between">
            <p>Pagamento:</p>
            <p>
              <strong>{getMetodoPagamentoLabel(donazione.metodo_pagamento)}</strong>
            </p>
          </div>
          <div className="space-between">
            <p>Volontario:</p>
            <p>
              <strong>{donazione.username_volontario}</strong>
            </p>
          </div>
          <div className="space-between">
            <p>Data:</p>
            <p>
              <strong>{donazione.created_at && moment(donazione.created_at).format('DD/MM/YYYY HH:mm')}</strong>
            </p>
          </div>
          {canRetryPayment && (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <Button variant="outlined" type="submit" onClick={() => setRetryModalOpen(true)}>
                  Ritenta il pagamento
                </Button>
              </div>
              <ConfirmModal
                open={retryModalOpen}
                title="Ritenta il pagamento"
                loading={loading}
                text={
                  <>
                    Stai ritentando il pagmento di
                    <br />
                    <span style={{ color: '#006F9A', fontWeight: '900' }}>
                      {formatPrice(+donazione.importo_totale)}
                    </span>
                    <br />
                    utilizzando il metodo di pagamento
                    <br />
                    <span style={{ color: '#006F9A', fontWeight: '900' }}>Pay By Link</span>
                  </>
                }
                confirmButtonText="Conferma"
                onCancel={() => setRetryModalOpen(false)}
                onConfirm={() => onRetryPayment(donazione)}
                backButtonText="Annulla"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DonazioneCard;
