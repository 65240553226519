/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../components/auth/AuthProvider';
import { schemaSummary } from '../components/validation/schemaSummary';
import { parseExternalEvents } from '../components/summary/utils/parseExternalEvents';
import { getSummaryData } from '../api/fetchSummary';
import { saveSummary } from '../api/saveSummary';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ISummaryData, ISummaryFormData } from '../interface/summary';
import { SkeletonForm } from '../components/commons/Skeleton';
import { sendSummary } from '../api/sendSummary';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ConfirmModal from '../components/commons/ConfirmModal';
import TabPanel from '../components/summary/TabPanel';
import ProductPanel from '../components/summary/Panels/ProductPanel';
import ContributionPanel from '../components/summary/Panels/ContributionPanel';
import RicevutePanel from '../components/summary/Panels/RicevutePanel';
import EventPanel from '../components/summary/Panels/EventPanel';
import { defaultSummaryValues } from '../components/summary/lib/defaultSummaryValues';

export const RendicontazioneSummary = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const summaryForm = useForm<ISummaryFormData>({
    resolver: yupResolver(schemaSummary),
    defaultValues: defaultSummaryValues,
  });

  const { handleSubmit, control, reset, getValues, setValue } = summaryForm;

  const useFormValues = () => {
    return {
      ...useWatch({ control }), // subscribe to form value updates
      ...getValues(), // always merge with latest form values
    };
  };

  const { token } = useAuth();
  const queryClient = useQueryClient();

  // const { data: product, isLoading: isLoadingProduct } = useQuery(['main-product'], () => getMainProduct(token), {
  //   refetchOnWindowFocus: false,
  //   staleTime: 30000,
  // });

  const { data: rendicontazione, isFetching: isLoadingSummary } = useQuery(
    ['summary-data'],
    () => getSummaryData(token),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: 0,
      onSuccess: (data) => {
        reset(data as any);
      },
    },
  );

  const { mutate: mutateCreate, isLoading } = useMutation<ISummaryData, Error, ISummaryData>((data: ISummaryData) => {
    return saveSummary(data, token);
  });

  const {
    qt_distribuiti_non_registrati,
    qt_distribuiti_digitale,
    tot_donazioni_non_registrati,
    tot_donazioni_digitale,
    tot_distribuiti_non_registrati,
    tot_distribuiti,
  } = useFormValues();

  useEffect(() => {
    const sommaDistribuiti = Number(qt_distribuiti_digitale || 0) + Number(qt_distribuiti_non_registrati || 0);
    const sommaContributi =
      Number(tot_donazioni_digitale || 0) +
      Number(tot_donazioni_non_registrati || 0) +
      Number(tot_distribuiti || 0) +
      Number(tot_distribuiti_non_registrati || 0);

    setValue('qt_distribuiti', sommaDistribuiti);
    setValue('tot_contributi', sommaContributi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tot_donazioni_non_registrati, qt_distribuiti_non_registrati, tot_distribuiti_non_registrati, tot_distribuiti]);

  // const mainProduct = useMemo(() => {
  //   if (product) {
  //     return product.nome.split(' ')[0];
  //   } else {
  //     return 'Prodotti';
  //   }
  // }, [product]);

  const closedEvent = !!rendicontazione?.sent;

  const onSubmit = (summaryFormdata: ISummaryFormData) => {
    const { qt_piazze_esterne, qt_date_piazze_esterne } = summaryFormdata;
    const parsedExternalEvents = parseExternalEvents(qt_piazze_esterne);
    const parsedExternalEventsTo = parseExternalEvents(qt_date_piazze_esterne);

    const data = {
      ...summaryFormdata,
      qt_piazze_esterne: parsedExternalEvents,
      qt_date_piazze_esterne: parsedExternalEventsTo,
    };

    delete data.qt_distribuiti_digitale;
    delete data.tot_donazioni_digitale;
    delete data.tot_distribuiti;

    mutateCreate(data, {
      onSuccess: () => {
        toast.success('La rendicontazione è salvata in bozza! Puoi modificarla quando vuoi.');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const checkTotalQuantity = (values: ISummaryFormData) => {
    const {
      qt_ricevuti,
      qt_piazze_esterne,
      qt_distribuiti,
      qt_non_utilizzabili,
      qt_rimanenze,
      qt_date_piazze_esterne,
    } = values;

    const piazzeEsterneTotalIn = qt_piazze_esterne.reduce((acc, curr) => acc + Number(curr.quantità), 0);
    const countIn = qt_ricevuti + piazzeEsterneTotalIn;

    const piazzeEsterneTotalOut = qt_date_piazze_esterne.reduce((acc, curr) => acc + Number(curr.quantità), 0);
    const countOut = qt_non_utilizzabili + qt_distribuiti + qt_rimanenze + piazzeEsterneTotalOut;

    return countIn === countOut;
  };

  const onCloseEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmOpen(true);
  };

  const handleCloseEvent = async () => {
    try {
      const formValues = getValues();
      if (!checkTotalQuantity(formValues)) {
        toast.error('Il totale delle quantità in ingresso non corrisponde al totale delle quantità in uscita!');
        return;
      }
      await sendSummary(token);
      toast.success("Hai chiuso l'evento! La rendicontazione sarà inviata agli uffici territoriali.");
      queryClient.invalidateQueries({ queryKey: ['summary-data'] });
      setConfirmOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (/*isLoadingProduct || */ isLoadingSummary) {
    return <SkeletonForm />;
  }

  return (
    <div className="container-main">
      <h1>Rendicontazione Allegato B</h1>
      <Tabs sx={{ width: '100%', mt: 1 }} variant="scrollable" value={tabValue} onChange={(_, v) => setTabValue(v)}>
        <Tab label="Prodotto" />
        <Tab label="Contributi" />
        <Tab label="Ricevute" />
        <Tab label="Evento" />
      </Tabs>

      {closedEvent && (
        <center>
          <i>
            <small>Evento chiuso non modificabile</small>
          </i>
        </center>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...summaryForm}>
          <TabPanel value={tabValue} index={0}>
            <ProductPanel mainProduct={'Prodotti'} closedEvent={closedEvent} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ContributionPanel closedEvent={closedEvent} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <RicevutePanel closedEvent={closedEvent} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <EventPanel closedEvent={closedEvent} />
          </TabPanel>
        </FormProvider>
        {closedEvent && (
          <center>
            <i>
              <small>Evento chiuso non modificabile</small>
            </i>
          </center>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1000,
            width: '100%',
            padding: '8px 16px',
            backgroundColor: 'white',
          }}
        >
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            type="submit"
            disabled={isLoading || closedEvent}
            startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
          >
            Salva
          </Button>
          <Button
            sx={{ flex: 1 }}
            variant="outlined"
            type="button"
            disabled={isLoading || closedEvent || !rendicontazione}
            startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
            onClick={onCloseEvent}
          >
            Chiudi evento
          </Button>
        </Box>
      </form>

      <ConfirmModal
        open={confirmOpen}
        title="Vuoi chiudere l'evento?"
        text="Chiudendo l'evento la rendicontazione sarà inviata agli uffici territoriali. Se chiudi l'evento non potrai riaprirlo."
        confirmButtonText="Chiudi evento"
        backButtonText="Annulla"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleCloseEvent()}
      />
    </div>
  );
};
