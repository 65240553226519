type Receipt = {
  type: MetodoPagamento.contanti | MetodoPagamento.pos;
  qty: number;
  data_ricevuta: Date;
};

enum MetodoPagamento {
  contanti = 'contanti',
  pos = 'pos',
}

export enum ScanType {
  document = 'document',
  receipt = 'receipt',
}

export interface OCRSearchParams {
  type: string | null;
  name: string | null;
}

export type scannedImmageResponse =
  | {
      scanType: ScanType.document;
      nome: string;
      cognome: string;
      data_nascita: string;
      codice_fiscale: string;
    }
  | {
      scanType: ScanType.receipt;
      cognome: string;
      nome: string;
      indirizzo: string;
      citta: string;
      cap: string;
      provincia: string;
      mail: string;
      metodo_pagamento: MetodoPagamento;
      importo: number;
    };

export type OCRResult = { receipt?: Receipt } & (
  | {
      responseType: 'FOUND_ONE';
      query?: never;
      id: number;
      data?: never;
    }
  | {
      responseType: 'FOUND_MANY';
      query: {
        nome: string;
        cognome: string;
        citta: string;
      };
      id?: never;
      data?: never;
    }
  | {
      responseType: 'NOT_FOUND';
      query?: never;
      id?: never;
      data: {
        nome?: string;
        cognome?: string;
        data_nascita?: string;
        codice_fiscale?: string;
      };
    }
);
